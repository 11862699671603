// import { VRButton } from 'three/examples/jsm/webxr/VRButton'
import { Component, AfterViewInit, ViewChild, HostListener, ElementRef } from '@angular/core';
declare const THREE: any;
declare const Object3D: any;
declare const FirstPersonControls: any;
declare const Water: any;
declare const Sky: any;

@Component({
  selector: 'home-canvas',
  templateUrl: './home-canvas.component.pug',
  styleUrls: ['./home-canvas.component.styl']
})
export class HomeCanvasComponent implements AfterViewInit {
  renderer = new THREE.WebGLRenderer({antialias: true, alpha: true}); //  
  scene = null;
  camera = null;
  mesh = null;
  rig = null;
  fpVrControls = null;
  controls = {};
  time: any;
  fpcontrols = null;
  clock = new THREE.Clock(true);
  havePointerLock = 'pointerLockElement' in document || 'mozPointerLockElement' in document || 'webkitPointerLockElement' in document;
  player = {
    height: .68,
    turnSpeed: .09,
    speed: .07,
    jumpHeight: .09,
    gravity: .01,
    velocity: 0,
    jumps: false
  };
  constructor() { 
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(65, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.rig = new THREE.Object3D();
    this.rig.add(this.camera);
    this.scene.add(this.rig);
    this.fpcontrols = new THREE.PointerLockControls( this.camera, this.renderer.domElement );
    this.fpcontrols.pointerSpeed=3;
    this.fpVrControls = new THREE.FirstPersonVRControls(this.camera, this.scene, this.rig);
    this.fpVrControls.verticalMovement = false;
    this.fpVrControls.strafing = true;
  }
  @ViewChild('rendererContainer',null) rendererContainer: ElementRef;
  @ViewChild('pageElement',null) pageElement: ElementRef;
  @HostListener('window:keydown', ['$event'])
  handleKeyDown(e) { 
    this.controls[e.keyCode] = true; 
  }
  @HostListener('window:keyup', ['$event'])
  handleKeyUp(e) { 
    this.controls[e.keyCode] = false; 
  }
  ngAfterViewInit() {
    this.configCamera();
    this.configRenderer();
    this.floor();
    this.createLight();
    this.createItems();
    this.celing()
    this.animate();
  }
  configCamera() {
    // this.camera.position.set(1, 0.2, -1.2);
    // Camera:Setup
    this.camera.position.set(0, this.player.height, -5);
    this.camera.lookAt(new THREE.Vector3(0, this.player.height, 0));
  }
  configRenderer() {
    this.renderer.setPixelRatio(window.devicePixelRatio);
    // this.renderer.setClearColor(new THREE.Color("hsl(0, 0%, 30%)"));
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.domElement.style.display = "block";
    this.renderer.domElement.style.margin = "auto";
    this.renderer.domElement.style.position = "absolute";
    this.renderer.domElement.style.top = "0";
    this.renderer.domElement.style.bottom = "0";
    this.renderer.domElement.style.left = "0";
    this.renderer.domElement.style.right = "0";
    this.renderer.shadowMap.enabled = true;
    this.renderer.xr.enabled = true;
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.shadowMap.enabled = true;
    this.renderer.toneMapping = THREE.ReinhardToneMapping;
    this.renderer.physicallyCorrectLights = true;
    this.scene.background = new THREE.Color("black");
    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);
    // this.pageElement.nativeElement.appendChild(VRButton.createButton( this.renderer.renderer ));
  }
  pointer_lock() {
    this.fpcontrols.lock();
  }
  control() {
    // Controls:Engine 
    if(this.controls[87]){ // w
      this.camera.position.x -= Math.sin(this.camera.rotation.y) * this.player.speed;
      this.camera.position.z -= -Math.cos(this.camera.rotation.y) * this.player.speed;
    }
    if(this.controls[83]){ // s
      this.camera.position.x += Math.sin(this.camera.rotation.y) * this.player.speed;
      this.camera.position.z += -Math.cos(this.camera.rotation.y) * this.player.speed;
    }
    if(this.controls[65]){ // a
      this.camera.position.x += Math.sin(this.camera.rotation.y + Math.PI / 2) * this.player.speed;
      this.camera.position.z += -Math.cos(this.camera.rotation.y + Math.PI / 2) * this.player.speed;
    }
    if(this.controls[68]){ // d
      this.camera.position.x += Math.sin(this.camera.rotation.y - Math.PI / 2) * this.player.speed;
      this.camera.position.z += -Math.cos(this.camera.rotation.y - Math.PI / 2) * this.player.speed;
    }
    if(this.controls[37]){ // la
      this.camera.rotation.y -= this.player.turnSpeed;
    }
    if(this.controls[39]){ // ra
      this.camera.rotation.y += this.player.turnSpeed;
    }
    if(this.controls[32]) { // space
      if(this.player.jumps) return false;
      this.player.jumps = true;
      this.player.velocity = -this.player.jumpHeight;
    }
  }

  floor() {
    var geometry = new THREE.PlaneGeometry(25,25);
    var material = new THREE.MeshStandardMaterial( {
          roughness: 0.8,
          color: 'white',
          metalness: 0.2,
          bumpScale: 0.0005
    });
    var textureLoader = new THREE.TextureLoader();
    textureLoader.load( "/assets/3d/hardwood2_diffuse.jpg", function ( map ) {
      map.wrapS = THREE.RepeatWrapping;
      map.wrapT = THREE.RepeatWrapping;
      map.anisotropy = 1;
      map.repeat.set(40, 96);
      map.encoding = THREE.sRGBEncoding;
      material.map = map;
      material.needsUpdate = true;
    });
    textureLoader.load( "/assets/3d/hardwood2_bump.jpg", function ( map ) {
      map.wrapS = THREE.RepeatWrapping;
      map.wrapT = THREE.RepeatWrapping;
      map.anisotropy = 1;
      map.repeat.set(40, 96);
      material.bumpMap = map;
      material.needsUpdate = true;
    });
    textureLoader.load( "/assets/3d/hardwood2_roughness.jpg", function ( map ) {
      map.wrapS = THREE.RepeatWrapping;
      map.wrapT = THREE.RepeatWrapping;
      map.anisotropy = 1;
      map.repeat.set(40, 96);
      material.roughnessMap = map;
      material.needsUpdate = true;
    });

    var floor = new THREE.Mesh( geometry, material );
        floor.rotation.x -= Math.PI / 2;
        floor.scale.x = 3;
        floor.scale.y = 3;
        floor.receiveShadow = true;
    this.scene.add( floor );


    // for (var i = 0; i < 4; ++i) {
    //   var geometry = new THREE.PlaneGeometry(27,100,1);
    //   geometry.roteteX=90*i;
    //   geometry.roteteY=0;
    //   geometry.roteteZ=90;
    //   geometry.positionX=100;
    //   geometry.positionY=100;
    //   geometry.positionZ=0;
    //   var material = new THREE.MeshStandardMaterial( {  color: 'brown' } );
    //   var mesh = new THREE.Mesh( geometry, material );
    //   this.scene.add( mesh );
    // }
  }
  celing() {
    var geometry = new THREE.PlaneGeometry(25,25);
    var material = new THREE.MeshStandardMaterial( {
      color:'white',
      emissive:'white',
      emissiveIntensity: 2,
      envMapIntensity: 1
    });
    var textureLoader = new THREE.TextureLoader();
    textureLoader.load( "/assets/3d/Ceiling_Open_Cell_001_SD/Ceiling_Open_Cell_001_ambientOcclusion.jpg", function ( map ) {
      map.wrapS = THREE.RepeatWrapping;
      map.wrapT = THREE.RepeatWrapping;
      map.repeat.set(5, 5);
      material.aoMap = map;
      material.needsUpdate = true;
    });
    var textureLoader = new THREE.TextureLoader();
    textureLoader.load( "/assets/3d/Ceiling_Open_Cell_001_SD/Ceiling_Open_Cell_001_basecolor.jpg", function ( map ) {
      map.wrapS = THREE.RepeatWrapping;
      map.wrapT = THREE.RepeatWrapping;
      map.repeat.set(5, 5);
      material.map = map;
      // material.needsUpdate = true;
    });
    var textureLoader = new THREE.TextureLoader();
    textureLoader.load( "/assets/3d/Ceiling_Open_Cell_001_SD/Ceiling_Open_Cell_001_emissive.jpg", function (map) {
      map.wrapS = THREE.RepeatWrapping;
      map.wrapT = THREE.RepeatWrapping;
      map.repeat.set(5, 5);
      material.emissiveMap = map;
      material.needsUpdate = true;
    });
    var textureLoader = new THREE.TextureLoader();
    textureLoader.load( "/assets/3d/Ceiling_Open_Cell_001_SD/Ceiling_Open_Cell_001_normal.jpg", function (map) {
      map.wrapS = THREE.RepeatWrapping;
      map.wrapT = THREE.RepeatWrapping;
      map.repeat.set(5, 5);
      material.normalMap = map;
      material.needsUpdate = true;
    });
    var textureLoader = new THREE.TextureLoader();
    textureLoader.load( "/assets/3d/Ceiling_Open_Cell_001_SD/Ceiling_Open_Cell_001_opacity.jpg", function (map) {
      map.wrapS = THREE.RepeatWrapping;
      map.wrapT = THREE.RepeatWrapping;
      map.repeat.set(5, 5);
      material.transparent = true;
      material.alphaMap = map;
      material.needsUpdate = true;
    });
    var textureLoader = new THREE.TextureLoader();
    textureLoader.load( "/assets/3d/Ceiling_Open_Cell_001_SD/Ceiling_Open_Cell_001_roughness.jpg", function (map) {
      map.wrapS = THREE.RepeatWrapping;
      map.wrapT = THREE.RepeatWrapping;
      map.repeat.set(5, 5);
      material.roughnessMap = map;
      material.needsUpdate = true;
    });
    var celing = new THREE.Mesh( geometry, material );
        celing.rotation.x += Math.PI / 2;
        celing.scale.x = 3;
        celing.scale.y = 3;
        celing.position.y = 9;
    this.scene.add( celing );
    // var upperceling = new THREE.Mesh( geometry, material );
    //     upperceling.rotation.x += Math.PI / 2;
    //     upperceling.scale.x = 3;
    //     upperceling.scale.y = 3;
    //     upperceling.position.y = 11;
    // this.scene.add( upperceling );
  }
  ixMovementUpdate() {
    this.player.velocity += this.player.gravity;
    this.camera.position.y -= this.player.velocity;
    if(this.camera.position.y < this.player.height) {
      this.camera.position.y = this.player.height;
      this.player.jumps = false;
    }
  }
  createLight() {
    // var light = new THREE.PointLight(0xffffff, 1)
    // light.position.set(0.32,2.2,-4.3);
    // light.castShadow = true;
    // light.shadow.camera.near = 2.5;
    // this.scene.add(light);

    // var light = new THREE.PointLight(0xffffff, 1)
    // light.position.set(-0.32,2.2,-4.3);
    // light.castShadow = true;
    // light.shadow.camera.near = 2.5;
    // this.scene.add(light);   

    var light = new THREE.PointLight(0xffffff, 0.8);
    light.position.set(0,0.9,-2);
    light.castShadow = true;
    light.shadow.camera.near = 2.5;
    this.scene.add(light);

    for (var x = 0; x < 3; ++x) {
      var light = new THREE.PointLight(0xffffff, 0.8);
      light.position.set(x*10,3,30);
      light.castShadow = true;
      this.scene.add(light);
    }

    // var light = new THREE.PointLight(0xffffff, 0.8)
    // light.position.set(0,3,2);
    // light.castShadow = true;
    // this.scene.add(light);

    // var hemiLight = new THREE.HemisphereLight( 0xddeeff, 0x0f0e0d, 0.02 );
    // this.scene.add( hemiLight );
  }

  createItems() {
    var geometry = new THREE.BoxBufferGeometry( 0.6, 1.2, 0.07 );
    var material = new THREE.MeshPhongMaterial({ 
      reflectivity:0.5,
      shininess:0.3,
      specular:'#120d0a'
    });
    var textureLoader = new THREE.TextureLoader();
    textureLoader.load( '/assets/3d/bamboo-wood-semigloss-bl/bamboo-wood-semigloss-albedo.png', function (maps) {
      material.map = maps;
      material.needsUpdate = true;
    });
    var textureLoader = new THREE.TextureLoader();
    textureLoader.load( "/assets/3d/bamboo-wood-semigloss-bl/bamboo-wood-semigloss-normal.png", function (map) {
      material.normalMap = map;
      material.needsUpdate = true;
    });
    var textureLoader = new THREE.TextureLoader();
    textureLoader.load( "/assets/3d/bamboo-wood-semigloss-bl/bamboo-wood-semigloss-roughness.png", function (maps) {
      material.roughnessMap = maps;
      material.needsUpdate = true;
    });
    var textureLoader = new THREE.TextureLoader();
    textureLoader.load( "/assets/3d/bamboo-wood-semigloss-bl/bamboo-wood-semigloss-ao.png", function (maps) {
      material.aoMap = maps;
      material.needsUpdate = true;
    });
    for (var i = 0; i < 3; ++i) {
      var mesh = new THREE.Mesh( geometry, material );
      mesh.position.x = -i*0.7;
      mesh.position.y = 0.55;
      mesh.position.z = -i*0.53;
      mesh.rotation.y = (i<4)?i*9:-10;
      mesh.castShadow = true;
      mesh.receiveShadow = true;
      this.scene.add( mesh ); 
    }
    for (var i = 0; i < 3; ++i) {
      var mesh = new THREE.Mesh( geometry, material );
      mesh.position.x = i*0.7;
      mesh.position.y = 0.55;
      mesh.position.z = -i*0.53;
      mesh.rotation.y = (i<4)?-i*9:10;
      mesh.receiveShadow = true;
      mesh.castShadow = true;
      this.scene.add( mesh );
    }
    // const fbxLoader = new THREE.FBXLoader();
    // fbxLoader.load('/assets/3d/Wooden+Table+1.fbx',(object) => {
    //     // object.traverse(function (child) {
    //     //     if ((child as THREE.Mesh).isMesh) {
    //     //         // (child as THREE.Mesh).material = material
    //     //         if ((child as THREE.Mesh).material) {
    //     //             ((child as THREE.Mesh).material as THREE.MeshBasicMaterial).transparent = false
    //     //         }
    //     //     }
    //     // })
    //     object.scale.set(.01, .01, .01)
    //     this.scene.add(object)
    // },(xhr) => {
    //     console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
    // },(error) => {
    //     console.log(error)
    // });
    // Instantiate a loader
    // const loader = new THREE.GLTFLoader();
    // const dracoLoader = new THREE.DRACOLoader();
    // dracoLoader.setDecoderPath( '/examples/js/libs/draco/' );
    // loader.setDRACOLoader( dracoLoader );
    // loader.load('/assets/3d/Wooden+Table+1.glb', function ( gltf ) {
    //     this.scene.add( gltf.scene );
    //     // gltf.animations; // Array<THREE.AnimationClip>
    //     // gltf.scene; // THREE.Group
    //     // gltf.scenes; // Array<THREE.Group>
    //     // gltf.cameras; // Array<THREE.Camera>
    //     // gltf.asset; // Object
    //   },
    //   function ( xhr ) {
    //     console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
    //   },
    //   function ( error ) {
    //     console.log( 'An error happened' );
    // });
  }

  animate() {
    window.requestAnimationFrame(() => this.animate());
    this.control();
    this.fpVrControls.update(this.clock.getDelta());
    this.ixMovementUpdate();
    this.render();
    this.time = Date.now();
  }
  render() {
    this.renderer.render( this.scene, this.camera );
  }
}