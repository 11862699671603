import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hero-spot',
  templateUrl: './hero.component.pug',
  styleUrls: ['./hero.component.styl']
})
export class HeroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
