import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { push,blip,blip_slow,icon_show,twist_off,fade } from '../../app.animations';

@Component({
  selector: 'dream-cannabis',
  templateUrl: './dream-cannabis-resume.component.pug',
  animations: [push,blip,blip_slow,icon_show,twist_off,fade],
  styleUrls: ['./dream-cannabis-resume.component.styl']
})

export class DreamCannabisResumeComponent implements OnInit {
	public constructor(private titleService: Title) { }
	loaded = false;
	ngOnInit() {
		this.titleService.setTitle("Liam Hogan's Resume");
		setTimeout( () => {
			this.loaded = true;
		}, 500);
	}

}
