import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger, state, animate, transition, style } from '@angular/animations';

// gooy menu
// https://codepen.io/lbebber/pen/rawQKR
// https://tympanus.net/Development/CreativeGooeyEffects/send.html

// .squarebtn
//    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);

export const show = trigger('show', [
    state('true', style({
        'pointer-events': 'all',
        'top': '0%',
        'opacity': '1',
        'width': '100%',
        'z-index': '20'
    })),
    state('false', style({
        'width': '100%',
        'top': '0%',
        'pointer-events': "none",
        'opacity': '0',
        'z-index': '-20'

    })),
    transition('true=>false', animate('500ms ease-out')),
    transition('false=>true', animate('500ms ease-out'))
]);

export const blip = trigger('blip', [
    state('true', style({
        'transform': 'scaleY(1)'
    })),
    state('false', style({
        'transform': 'scaleY(0)'
    })),
    transition('true=>false', animate('320ms ease-out')),
    transition('false=>true', animate('320ms 300ms ease-out'))
]);

export const blip_slow = trigger('blip_slow', [
    state('true', style({
        'transform': 'scaleY(1)',
        'opacity': 1
    })),
    state('false', style({
        'transform': 'scaleY(0.5)',
        'opacity': 0
    })),
    transition('true=>false', animate('0ms ease-out')),
    transition('false=>true', animate('520ms 600ms ease-out'))
]);

export const rotate = trigger('rotate', [
    state('true', style({
        'transform': 'rotate(180deg)'
    })),
    state('false', style({
        'transform': 'rotate(180deg)'
    })),
    transition('true=>false', animate('700ms ease-out')),
    transition('false=>true', animate('500ms 500ms ease-out'))
]);

export const fade = trigger('fade', [
    state('true', style({
        opacity: 1,
        'pointer-events': "all"
    })),
    state('false', style({
        opacity: 0,
        'pointer-events': "none"
    })),
    transition('true=>false', animate('700ms ease-out')),
    transition('false=>true', animate('500ms 500ms ease-out'))
]);

export const fade_quick = trigger('fade_quick', [
    state('true', style({
        opacity: 1,
        'pointer-events': "all"
    })),
    state('false', style({
        opacity: 0,
        'pointer-events': "none"
    })),
    transition('*=>*', animate('500ms ease-out'))
]);

export const height_fade = trigger('height_fade', [
    state('true', style({
        opacity: 1,
        'pointer-events': "all"
    })),
    state('false', style({
        opacity: 0,
        'pointer-events': "none",
        height: 0,
        'padding-top': 0,
        'padding-bottom': 0,
        'margin-top': 0,
        'margin-bottom': 0
    })),
    transition('true=>false', animate('700ms ease-out')),
    transition('false=>true', animate('700ms ease-out'))
]);

export const height = trigger('height', [
    state('true', style({
        'height': '344px'
    })),
    state('false', style({
        'height': '0px'
    })),
    transition('*=>*', animate('300ms ease-out'))
]);

export const blur = trigger('blur', [
    state('true', style({
        opacity: 1,
        'pointer-events': "all",
        'filter':'blur(0px)'
    })),
    state('false', style({
        opacity: 0,
        'pointer-events': "none",
        'filter':'blur(2px)'
    })),
    transition('*=>*', animate('456ms ease-out'))
]);

export const push = trigger('push', [
    state('false', style({
        'transform': 'translateY(0%)'
    })),
    state('down', style({
        'transform': 'translateY(-9px)'
    })),
    state('up', style({
        'transform': 'translateY(9px)'
    })),
    transition('up=>false', animate('830ms ease-out')),
    transition('down=>false', animate('830ms ease-out')),
    transition('false=>*', animate('120ms'))
]);

export const display_info = trigger('display_info', [
    state('true', style({
        'opacity': 1,
        'transform': 'translateY(0%)',
        'pointer-events': 'all'
    })),
    state('false', style({
        'opacity': 0,
        'transform': 'translateY(-9px)',
        'pointer-events': 'none'
    })),
    transition('*=>*', animate('330ms ease-out'))
]);

export const display_icon = trigger('display_icon', [
    state('true', style({
        'opacity': 1,
        'transform': 'translateY(-22px)'
    })),
    state('false', style({
        'opacity': 0.8,
        'transform': 'translateY(0px)'
    })),
    state('hide', style({
        'opacity': 0
    })),
    transition('*=>*', animate('330ms ease-out'))
]);

export const skill_open = trigger('skill_open', [
    state('true', style({
        'transform': 'transformY(-4px)'
    })),
    state('false', style({
        'transform': 'transformY(0px)'
    })),
    transition('*=>*', animate('830ms ease-out'))
]);


export const show_wit = trigger('show_wit', [
    state('true', style({
        'top': '15px',
        'height': '165px'
    })),
    state('false', style({
        'top': '38%',
        'height': '165px'
    })),
    transition('*=>*', animate('420ms ease-out'))
]);

export const icon_hover = trigger('icon_hover', [
    state('true', style({
        'transform': 'scale(1.07)',
        'z-index': 2
    })),
    state('false', style({
        'transform': 'scale(1)',
        'z-index': 1
    })),
    transition('*=>*', animate('320ms ease-out')),
]);
export const icon_show = trigger('icon_show', [
    state('true', style({
        'opacity': '1',
        'pointer-events': 'all',
        'width': '90%',
        'height': '100%',
        'z-index': '10 !important',
        'position': 'fixed',
        'left': '5%',
        'top': '120px',
        'max-width': 'none',
        'overflow-y': 'scroll',
    })),
    state('false', style({
        'overflow': 'hidden'
    }))
]);
export const twist_off = trigger('twist_off', [
    state('false', style({
        'transform': 'rotateY(45deg)',
        'opacity': 0
    })),
    state('true', style({
        'transform': 'rotateY(0deg)',
        'opacity': 1
    })),
    transition('false => true', animate('394ms ease-out')),
    transition('true => false', animate('0ms'))
]);
export const twist_off_2 = trigger('twist_off_2', [
    state('false', style({
        'transform': 'rotateY(90deg)',
        'opacity': 0
    })),
    state('true', style({
        'transform': 'rotateY(0deg)',
        'opacity': 1
    })),
    transition('*=>*', animate('260ms'))
]);
export const flip_enter = trigger('flip_enter', [
    state('false', style({
        'transform': 'scaleX(0)'
    })),
    state('true', style({
        'transform': 'scaleX(1)'
    })),
    transition('*=>*', animate('300ms'))
]);
export const slidetotheright = trigger('slidetotheright', [
    state('false', style({
        'left': '-217px'
    })),
    state('true', style({
        'left': '0%'
    })),
    transition('*=>*', animate('300ms'))
]);