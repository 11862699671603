import { Component, Input, Output, OnInit, OnChanges, HostListener, EventEmitter } from '@angular/core';
import { push, fade, height, blur, show } from '../app.animations';

@Component({
  selector: 'home-sapling',
  animations: [ push, fade, height, blur, show ],
  templateUrl: './sapling.component.pug',
  styleUrls: ['./sapling.component.styl']
})

export class SaplingComponent implements OnInit, OnChanges {
	
	// @HostListener('wheel', ['$event'])
	// scroll(event) {
	// 	if ( event.wheelDelta < 0 ) {
	// 		this.down = (this.down!='up') ? 'down' : 'false';
	// 	} else {
	// 		this.down = (this.down!='down') ? 'up' : 'false';
	// 	}
	// 	setTimeout(()=>{ 
	// 		this.down = 'false'; 
	// 	}, 420);
	// }
	@HostListener('window:resize', ['$event'])
	onResize(event) {this.screenSize();}
	screenSize() {
		this.innerWidth=window.innerWidth;
		if(this.showMenu){
			if( this.innerWidth<600 ) {
				this.showMenu=false;
			} else {
				this.showMenu=true;
			}
		}
	}
	@Output('home') go_home = new EventEmitter();
	@Output('page') go_to_page = new EventEmitter();
	@Input('saplingInput') si = 0;
	@Input('saplingOpening') op = false;
	public innerWidth: any;
	liamhogan = '';
	down = 'false';
	u  = 'C';
	vid = false;
	bitcoin = false;
	music = false;
	serverstack = false;
	show_music = false;
	showMenu = true;
	openLogo = false;
	hide_camera = false;
	showSubtitle = false;
	showApps = false;
	showSubtitleTwo = false;
	_up() {
		console.log('_up');
		this.change(this.si+1);
	}
	_down() {
		console.log('_down');
		this.change(this.si-1);
	}
	home() { this.go_home.emit(0); }
	change(a) { 
		this.showMenu=false;
		this.go_to_page.emit(a); 
	}
	blog() {
		// /document.location='http://hogan.re/';
	}
	github() { window.open('https://github.com/bentbot/');}
	cheques() { window.open('https://play.google.com/store/apps/details?id=pro.cheques.app');}
	timesheets() { window.open('http://cheques.janglehost.com/timesheets');}
	ngOnChanges(item) {
		if(item.si) {
			if(this.si==0){
				this.showMenu=true;
			} else {
				this.showMenu=false;
			}
		}
	}
	ngOnInit() {
		this.screenSize();
		setTimeout( () => {this.openLogo = true;}, 1000);
		setTimeout( () => {this.hide_camera = true;}, 22000);
		// setTimeout( () => {if(this.si==0){this.change(1);}}, 47000);
		setTimeout( () => {
			var name = ['L', 'I', 'A', 'M', ' ', 'H', 'O', 'G', 'A', 'N'];
			var i = 0;
			var interval = setInterval( () => { 
				this.liamhogan = this.liamhogan + name[i]; i++; 
				if ( i > name.length-1 ) {
					this.showSubtitle = true;
					setInterval( () => { 
						this.showSubtitleTwo = true;
						setInterval( () => { this.serverstack = true;}, 333);
						setInterval( () => { this.bitcoin = true;}, 777);
						this.music = true;
						this.vid = true;
					}, 500);
					clearInterval(interval);
				}
			}, 130);
		}, 1500);
	}
}