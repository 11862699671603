import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

import './polyfills';

// window['THREE'] = THREE;

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// import('three/examples/js/controls/FirstPersonControls').then(() => {
// import('three/examples/js/controls/PointerLockControls').then(() => {
// import('three-firstperson-vr-controls/build/FirstPersonVRControls.browser.min').then(() => {
//   import('three/src/core/Object3D').then(() => { 
//   import('three/examples/js/loaders/FBXLoader').then(() => {
//   import('three/examples/js/loaders/GLTFLoader').then(() => {
//   import('three/examples/js/loaders/DRACOLoader').then(() => {
//     import('three/examples/js/objects/Water').then(() => {
//       import('three/examples/js/objects/Sky').then(() => {
//         platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {
//           // Ensure Angular destroys itself on hot reloads.
//           if (window['ngRef']) {
//             window['ngRef'].destroy();
//           }
//           window['ngRef'] = ref;
//           // Otherise, log the boot error
//         }).catch(err => console.error(err));
//       });
//     });
//   });
//   });
//   });
//   });
//   });
//   });
// });