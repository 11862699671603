import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'singsnap-coverletter',
  templateUrl: './singsnap.coverletter.component.pug',
  styleUrls: ['./singsnap.coverletter.component.styl']
})
export class CoverletterSingSnapComponent implements OnInit {

	public constructor(private titleService: Title) { }
  
	title: string;
	subtitle: string;
	tirtitle: string;
	company: string;

	ngOnInit() {
		this.title='SingSnap';
		this.subtitle='Ottawa';
		this.titleService.setTitle("Liam Hogan's SingSnap Cover Letter");
		this.tirtitle='';
		this.company='SingSnap'; 
	}

}
