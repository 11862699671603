import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResumeComponent } from './resume/resume.component';
import { CoverletterComponent } from './coverletter/coverletter.component';
import { SalesResumeComponent } from './resume/dutch-love/sales.resume.component';
import { CannNorthResumeComponent } from './resume/cann-north/cann-north-resume.component';
import { CoverletterDutchLoveComponent } from './coverletter/dutch-love/dutch-love.coverletter.component';
import { CoverletterMitelComponent } from './coverletter/mitel/coverletter.component';
import { DNSCoverletterComponent } from './coverletter/dns-solutions/dns.coverletter.component';
import { FreenicsCoverletterComponent } from './coverletter/freenics/freenics.coverletter.component';
import { CoverletterWikileafComponent } from './coverletter/wikileaf/wikileaf.coverletter.component';
import { CoverletterHayleComponent } from './coverletter/hayle/hayle.coverletter.component';
import { SaplingComponent } from './sapling/sapling.component';
import { FortinetCoverletterComponent } from './coverletter/fortinet/fortinet.coverletter.component';
import { HomeComponent } from './home/home.component';
import { CoverletterCanadasCultureComponent } from './coverletter/canadas-culture/canadas-culture.component';
import { CoverletterDreamCannabisComponent } from './coverletter/dream-cannabis/dream-cannabis.coverletter.component';
import { DreamCannabisResumeComponent } from './resume/dream-cannabis/dream-cannabis-resume.component';
import { DeveloperResumeComponent } from './resume/developer/developer-resume.component';
import { CoverletterSingSnapComponent } from './coverletter/singsnap/singsnap.coverletter.component';
const routes: Routes = [
	{ path: '', component: HomeComponent },
	{ path: 'home', component: HomeComponent },
	{ path: 'coverletter', component: CoverletterComponent },
	{ path: 'cover-letter', component: CoverletterComponent },
	{ path: 'coverletter/freenics', component: FreenicsCoverletterComponent },
	{ path: 'coverletter/mitel', component: CoverletterMitelComponent },
	{ path: 'cover-letter/mitel', component: CoverletterMitelComponent },
	{ path: 'fullscript/cover', component: CoverletterComponent },
	{ path: 'mitel/coverletter', component: CoverletterMitelComponent },
	{ path: 'freenics/coverletter', component: FreenicsCoverletterComponent },
	{ path: 'coverletter/canadas-culture', component: CoverletterCanadasCultureComponent },
	{ path: 'coverletter/dns-solutions', component: DNSCoverletterComponent },
	{ path: 'cover-letter/canadas-culture', component: CoverletterCanadasCultureComponent },
	{ path: 'cover-letter/dns-solutions', component: DNSCoverletterComponent },
	{ path: 'cover-letter/canadasculture', component: CoverletterCanadasCultureComponent },
	{ path: 'coverletter/canadasculture', component: CoverletterCanadasCultureComponent },
	{ path: 'coverletter/fortinet', component: FortinetCoverletterComponent },
	{ path: 'cover-letter/fortinet', component: FortinetCoverletterComponent },
	{ path: 'coverletter/cannanorth', component: CoverletterCanadasCultureComponent },
	{ path: 'coverletter/dream-cannabis', component: CoverletterDreamCannabisComponent },
	{ path: 'canadas-culture/coverletter', component: CoverletterCanadasCultureComponent },
	{ path: 'hayle', component: CoverletterHayleComponent },
	{ path: 'hayle/coverletter', component: CoverletterHayleComponent },
	{ path: 'wikileaf', component: CoverletterWikileafComponent },
	{ path: 'wikileaf/coverletter', component: CoverletterWikileafComponent },
	{ path: 'dream-cannabis', component: DreamCannabisResumeComponent },
	{ path: 'canna-north', component: CannNorthResumeComponent },
	{ path: 'dutch-love', component: SalesResumeComponent },
	{ path: 'dutch-love/coverletter', component: CoverletterDutchLoveComponent },
	{ path: 'dutch-love/cover-letter', component: CoverletterDutchLoveComponent },
	{ path: 'singsnap', component: CoverletterSingSnapComponent },
	{ path: 'singsnap/coverletter', component: CoverletterSingSnapComponent },
	{ path: 'sales-resume', component: SalesResumeComponent },
	{ path: 'resume', component: DeveloperResumeComponent },
	{ path: 'sapling', component: SaplingComponent },
	{ path: ':page', component: HomeComponent },
	{ path: '**', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
