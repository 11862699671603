import { Component, OnInit, Input } from '@angular/core';
import { push,blip,blip_slow,icon_show,show,twist_off } from '../app.animations';
import { Email } from '../services/email';
import { StoreService } from '../services/store.service';

@Component({
  selector: 'home-contact',
  templateUrl: './home-contact.component.pug',
  animations: [push,blip,blip_slow,icon_show,show,twist_off],
  styleUrls: ['./home-contact.component.styl']
})
export class HomeContactComponent implements OnInit {

	constructor(private site: StoreService) { }
	
	@Input('si') si = 0;
	@Input('ss') ss = 0;
	@Input('sss') sss = 0;
	@Input('sm') sm = 6;

	sending: boolean
	email: Email;
	contactForm = new Contact();
	emailSent: Email;

	sendEmail() {
		this.sending = true;
		this.site.SendEmail(this.contactForm).subscribe( (r) => {
		this.emailSent = r[0];
			setTimeout( () => {this.sending = false;}, 500);
		});
	}

	ngOnInit() {

	}
}

export class Contact {
  constructor(
    public email?: string,
    public subject?: string,
    public message?: string,
  ) {  }
}