import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { RouterModule, Routes} from '@angular/router';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG, Title } from '@angular/platform-browser';
import { SocketIoModule, SocketIoConfig, Socket } from 'ng-socket-io';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreService } from './services/store.service';
import { ResumeComponent } from './resume/resume.component';
import { SaplingComponent } from './sapling/sapling.component';
import { HomeComponent } from './home/home.component';
import { HomeChequesComponent } from './home-cheques/home-cheques.component';
import { HomeWorkComponent } from './home-work/home-work.component';
import { HomeMediaComponent } from './home-media/home-media.component';
import { HomeBigDataComponent } from './home-big-data/home-big-data.component';
import { HomePilotComponent } from './home-pilot/home-pilot.component';
import { HomeContactComponent } from './home-contact/home-contact.component';
import { ContactComponent } from './contact/contact.component';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { HeroComponent } from './hero/hero.component';
import { VoteComponent } from './home-big-data/vote/vote.component';
import { HomeSitesComponent } from './home-sites/home-sites.component';
import { SalesResumeComponent } from './resume/dutch-love/sales.resume.component';
import { CannNorthResumeComponent } from './resume/cann-north/cann-north-resume.component';
import { DreamCannabisResumeComponent } from './resume/dream-cannabis/dream-cannabis-resume.component';
import { DeveloperResumeComponent } from './resume/developer/developer-resume.component';
import { CoverletterSingSnapComponent } from './coverletter/singsnap/singsnap.coverletter.component';
import { FortinetCoverletterComponent } from './coverletter/fortinet/fortinet.coverletter.component';
import { CoverletterComponent } from './coverletter/coverletter.component';
import { FreenicsCoverletterComponent } from './coverletter/freenics/freenics.coverletter.component';
import { CoverletterDutchLoveComponent } from './coverletter/dutch-love/dutch-love.coverletter.component';
import { CoverletterDreamCannabisComponent } from './coverletter/dream-cannabis/dream-cannabis.coverletter.component';
import { CoverletterCanadasCultureComponent } from './coverletter/canadas-culture/canadas-culture.component';
import { DNSCoverletterComponent } from './coverletter/dns-solutions/dns.coverletter.component';
import { CoverletterMitelComponent } from './coverletter/mitel/coverletter.component';
import { CoverletterWikileafComponent } from './coverletter/wikileaf/wikileaf.coverletter.component';
import { CoverletterHayleComponent } from './coverletter/hayle/hayle.coverletter.component';
import { environment } from '../environments/environment';
import { HomeCanvasComponent } from './home-canvas/home-canvas.component';
import { PhoneComponent } from './phone/phone.component';
import * as Hammer from 'hammerjs';

const config: SocketIoConfig = { 
    url: environment.socket, 
    options: { 
      rejectUnauthorized: false, 
      secure: environment.secure_socket
    }
};
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}
@NgModule({
  declarations: [
    AppComponent,
    ResumeComponent,
    SaplingComponent,
    HomeComponent,
    HomeWorkComponent,
    HomeMediaComponent,
    HomeBigDataComponent,
    HomePilotComponent,
    HomeChequesComponent,
    HomeContactComponent,
    ContactComponent,
    HeroComponent,
    VoteComponent,
    HomeSitesComponent,
    SalesResumeComponent,
    CoverletterComponent,
    DNSCoverletterComponent,
    CannNorthResumeComponent,
    DeveloperResumeComponent,
    DreamCannabisResumeComponent,
    CoverletterHayleComponent,
    CoverletterMitelComponent,
    CoverletterSingSnapComponent,
    FortinetCoverletterComponent,
    CoverletterWikileafComponent,
    CoverletterDutchLoveComponent,
    CoverletterDreamCannabisComponent,
    CoverletterCanadasCultureComponent,
    FreenicsCoverletterComponent,
    HomeCanvasComponent,
    PhoneComponent,
  ],
  imports: [
    HttpModule,
    BrowserModule,
    BrowserAnimationsModule,
    AngularFontAwesomeModule,
    FormsModule,
    AppRoutingModule,
    SocketIoModule.forRoot(config)
  ],
  providers: [
    StoreService,
    CookieService,
    Title,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
