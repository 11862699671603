import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'dream-cannabis-coverletter',
  templateUrl: './dream-cannabis.coverletter.component.pug',
  styleUrls: ['./dream-cannabis.coverletter.component.styl']
})
export class CoverletterDreamCannabisComponent implements OnInit {

	public constructor(private titleService: Title) { }
  
	title: string;
	subtitle: string;
	tirtitle: string;
	company: string;

	ngOnInit() {
		this.title='Dream Cannabis';
		this.subtitle='250 Greenbank Rd #1B';
		this.titleService.setTitle("Liam's Dream Cannabis Cover Letter");
		this.tirtitle='';
		this.company='Dream Cannabis'; 
	}

}
