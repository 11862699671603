import { BrowserModule } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Component, OnInit, HostListener } from '@angular/core';
import { StoreService } from '../services/store.service';
import { show, push, fade, slidetotheright, display_info, display_icon, height_fade, flip_enter, skill_open, rotate, blip, blip_slow, height, show_wit, icon_hover, icon_show, blur, twist_off } from '../app.animations';
@Component({
  selector: 'home',
  animations: [ show, push, fade, slidetotheright, display_info, display_icon, height_fade, flip_enter, skill_open, rotate, blip, blip_slow, height, show_wit, icon_hover, icon_show,  blur, twist_off ],
  templateUrl: './home.component.pug',
  styleUrls: ['./home.component.styl']
})
export class HomeComponent implements OnInit {
	@HostListener('wheel', ['$event'])
	scroll(event) {
		if ( this.simg > -1 ) return;
		if ( event.wheelDelta < 0 ) {
			this._down();
		} else {
			this._up();
		}
		setTimeout(()=>{ 
			this.down = 'false'; 
			this.counter = 0; 
		}, this.fade_time);
		this.site.sendLove().subscribe( (r) => {
			// console.log(r);
		});
	}
	@HostListener('document:keyup', ['$event'])
	onKeyUp(event) {
		if (event.key=="ArrowDown"||event.key=="PageDown"){
			this._down();
		}
		if (event.key=="ArrowUp"||event.key=="PageUp"){
			this._up();
		}
		setTimeout(()=>{ 
			this.down = 'false'; 
			this.counter = 0; 
		}, this.fade_time);
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.resolution();
		this.pb_j();
	}
	_up() {
		this.counter = this.counter-1;
		this.down = (this.down!='down') ? 'up' : 'false';
		if ( this.counter == -1 && this.si > 0 ) this.change(  this.si - 1 );
	}
	_down() {
		this.counter = this.counter+1;
		this.down = (this.down!='up') ? 'down' : 'false';
		if ( this.counter == 1 && this.si < this.sm) this.change( this.si + 1 );
	}
	page: string;
	si = 0;
	ss = 7;
	sss = 0;
	sm = 7;
	fade_time = 1000;
	bg_loaded = false;
	red = '#950013';
	info: any;
	counter=0;
	box_interval: any;
	showMenu = true;
	t_off = false;
	down = 'false';
	showStagger=0;
	boxes_show=0;
	h1 = 'false';
	p1 = 'false';
	ch = false;
	op = false;
	simg = -1;    
	showV = false;
	elec_hov = false;
	returning_visitor = false;
	background_source='/assets/img/Desk_2021.jpeg';
	pages = [
		{name:"Home",color: '#07c327' },
		{name:"My Passion",color: 'rgb(0, 0, 0)' },
		{name:"Hand Crafted Media",color: 'rgb(0, 0, 0)' }, //#07c327
		{name:"Site Portfolio",color: 'rgb(243 103 0)' },
		{name:"Pilot.Plus",color: '#ffffff' }, //'#07c327'
		{name:"Increments",color: this.red },
		{name:"Contact",color: '#1a237e' },
	];

	ico_shw=0;
	ico_hov=[];
	ico_i = 0;
	ico_x = 1;
	boxes=[];
	selectedMedia = 0;
	Medialength = 0;
	TileBoxes = 0;

	constructor(private router: ActivatedRoute,
				private site: StoreService) {
		this.ico_hov[1] = 1;
		this.resolution();
		this.page = this.router.snapshot.paramMap.get("page");
	}

	resolution() {
		this.boxes =[];
		this.TileBoxes = Math.ceil(window.innerHeight / 50) * Math.ceil(window.innerWidth / 50)+50;
		for (var i = this.TileBoxes; i >= 0; i--) {
			this.boxes.push(1);
		}
	}

	redd() {
		this.red = (this.elec_hov) ? '#950013' : '#f3f3f3';
	}

	swup() {
		console.log('swipe','up')
		this.change(this.si+1);
	}

	swdn() {
		console.log('swipe','down')
		this.change(this.si-1);
	}

	change( i ) {
		if(i<0)return;
  		if ( !this.ch ) {

  			this.si = i;
  			this.ch = true;
  			this.simg = -1;
			this.counter = 0;
			this.sss = 0;

			if (this.si == 1) { 
				setTimeout(() => { this.h1 = 'true'; }, 300);
				setTimeout(() => { this.p1 = 'true'; }, 1240);
				setTimeout(() => { this.stagger(); }, 1300);
				this.pb_j();
			}

			if (this.si == 2) { 
				setTimeout(() => { this.stagger_ico(); }, 400);
			}
			
			setTimeout(() => { 
				this.ch = false;
				if (this.info != '') this.info = ''; 
			}, this.fade_time);

			setTimeout(() => { this.ss=this.si }, 700);
			setTimeout(() => { this.sss=this.si }, 2500);

		}
	}

	stagger() {
		setTimeout( () => {
			this.showStagger = this.showStagger + 1;
			if ( this.showStagger < 6 ) this.stagger();
		}, 300);
	}

	stagger_ico() {
		if ( this.ico_shw < this.ico_x ) {
			this.ico_shw = this.ico_shw + 1; 
			setTimeout( () => { this.stagger_ico() }, 500 );
		}
	}


	pb_j(v?) {
		this.boxes_show = this.boxes_show + 1;
		if ( !v ) {
			this.box_interval = setInterval( () => {
				this.pb_j(true);
			}, 1);
		} else {
			if ( this.boxes_show >= this.TileBoxes ) {
				clearInterval(this.box_interval);
			}
		}
	}

	ngOnInit() {
  	this.sm = this.pages.length;
  	this.page = this.router.snapshot.paramMap.get("page");
		switch (this.page) {
			case 'home':
				this.change(0);
			break;
			case 'media':
				this.change(1);
			break;
			case 'passion':
				this.change(2);
			break;
			case 'data':
				this.change(3);
			break;
			case 'portfolio':
				this.change(4);
			break;
			case 'contact':
				this.change(5);
			break;
			default:
				this.change(0);
			break;
		}
		if(localStorage.getItem('returning_visitor')==='1'){
			this.returning_visitor=true;
		}else{localStorage.setItem('returning_visitor','1');}
  	setTimeout( ()=> { this.showMenu = false; }, 2000);
	}

}
