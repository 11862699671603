import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { show,push,blip,blip_slow,icon_show,twist_off,fade } from '../app.animations';

@Component({
  selector: 'home-cheques',
  templateUrl: './home-cheques.component.pug',
  animations: [show,push,blip,blip_slow,icon_show,twist_off,fade],
  styleUrls: ['./home-cheques.component.styl']
})
export class HomeChequesComponent implements OnInit {

  constructor() { }
  red: any;
  showV = false;
  elec_hov = false;
  @Input('si') si = 0;
  @Input('ss') ss = 0;
  @Input('sss') sss = 0;
  @Input('sm') sm = 6;
  image1 = false;
  image2 = false;
  play() {
  }
  ngOnInit() {
  }

}
