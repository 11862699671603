import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { push,blip,blip_slow,icon_show,twist_off,show,fade,height_fade,fade_quick } from '../app.animations';

@Component({
  selector: 'home-media',
  templateUrl: './home-media.component.pug',
  animations: [push,blip,blip_slow,icon_show,twist_off,show,fade,height_fade,fade_quick],
  styleUrls: ['./home-media.component.styl']
})
export class HomeMediaComponent implements OnInit {
	constructor() { this.Medialength = this.media.length; }
	media = [
		// { name: "Dan Hogan", url: "http://danhogan.ca", image: "/assets/img/danhogan.png", image2:"/assets/img/danhogan.png", color: "black" },
		{ name: "IDENTITY", url: "http://identityrpg.com/", video: "/assets/video/identity.webm", image: "/assets/img/identity.jpg", color: "#000" },
		// { name: "Tree St.", url: "https://treest.ca", image: "/assets/img/TSTfront-white.png", image2:"/assets/img/TSfront-black2.png", color: "black" },
		// { name: "Shaw Centre", url: "https://www.shaw-centre.com/", image: "/assets/img/shaw.png", color: "black" },
		// { name: "Aardvark Private Homes", image: "/assets/img/Aardvark-Professional-Profile-cA-1.jpg", color: "black" },
		// { name: "Lansdowne Live", url: "https://www.tdplace.ca", image: "/assets/img/Screen-Shot-2015-11-19-at-3.13.00-AM.png", color: "black" },
		// { name: "Propel Property Management", url: "http://propelpm.com/", image: "/assets/img/download-2-816x917-1.png", color: "black" },
		// { name: "Bluesfest 2015", url: "http://www.ottawabluesfest.ca/", image: "/assets/img/bluesfes.png", color: "black" },
		// { name: "Goldenear", url: "https://www.goldenear.com/", image: "/assets/img/goldenear-816x946-1.png", color: "black" },
		// // { name: "Elemental Data Collection", url: "http://elementaldci.com/", image: "/assets/img/elemental.png", color: "black" },
		// { name: "JangleHost.com", url: "http://janglehost.com", image: "/assets/img/jangle.png", color: "black" },
		// { name: "Carlington Community Centre", url: "https://www.carlington.ochc.org/", image: "/assets/img/Screen-Shot-2015-11-19-at-3.11.02-AM.png", color: "black" },
		// { name: "Brentech Signs & Designs", url: "http://brentech.ca/", image: "/assets/img/brentechsigns-1.png", color: "black" },
	]

	wind_x = this.media.length;
	Medialength = 0;
	wind_i = 0;
	wind_shw=[];
	wind_hov=0;
	ico_shw=0;
	ico_hov=[];
	ico_i = 0;
	ico_x = 1;
	@Input('si') si = 0;
  	@Input('ss') ss = 0;
  	@Input('sss') sss = 0;
  	@Input('sm') sm = 6;
  	@ViewChild("videoPlayer", { static: false }) video: ElementRef;
	play() {
		this.video.nativeElement.play();
	}
	ngOnInit() {

	}

}
