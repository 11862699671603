import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'dns-coverletter',
  templateUrl: './dns.coverletter.component.pug',
  styleUrls: ['./dns.coverletter.component.styl']
})
export class DNSCoverletterComponent implements OnInit {

	public constructor(private titleService: Title) { }
  
	title: string;
	subtitle: string;
	tirtitle: string;
	company: string;

	ngOnInit() {
		this.title='DNSnetworks';
		this.subtitle='Shawn Ebbs';
		this.titleService.setTitle("Letter from Liam Hogan");
		this.tirtitle='CEO';
		this.company='DNSnetworks';
	}

}
