import { Component, OnInit, Input } from '@angular/core';
import { flip_enter, display_info, show, display_icon} from '../app.animations';

@Component({
  selector: 'home-work',
  templateUrl: './home-work.component.pug',
  animations: [flip_enter, display_info, show, display_icon],
  styleUrls: ['./home-work.component.styl']
})
export class HomeWorkComponent implements OnInit {

  constructor() { }

  	@Input('si') si = 0
  	@Input('ss') ss = 0
  	@Input('sss') sss = 0
  	@Input('sm') sm = 6
  	@Input('boxes') boxes=[]
  	@Input('boxes_show') boxes_show=0
  	@Input('showStagger') showStagger=0
  	@Input('info') info: any
  	@Input('h1') h1 = 'false'
  	@Input('p1') p1 = 'false'


	view_info(a?) {
		if ( this.h1 && this.p1 ) {
			this.info = (this.info == a) ? false : a;
			if ( !this.info ) this.hide_show_paragraph();
			if ( this.info ) this.hide_show_paragraph(1);
		}
	}

	hide_show_paragraph( hide? ) {
		let n = 'true';
		if ( hide ) n = 'false';
		this.h1 = n;
		this.p1 = n;
	}

  ngOnInit() {
    for (var a = 0; a < 7; a++) {
      
    }
  }

}
