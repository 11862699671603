import { Component, OnInit, Input } from '@angular/core';
import { push,blip,blip_slow,icon_show,show,twist_off,fade,fade_quick,twist_off_2 } from '../app.animations';

@Component({
  selector: 'home-big-data',
  templateUrl: './home-big-data.component.pug',
  animations: [push,blip,blip_slow,icon_show,show,twist_off,fade,fade_quick,twist_off_2],
  styleUrls: ['./home-big-data.component.styl']
})
export class HomeBigDataComponent implements OnInit {

  constructor() { }
  red: any;
  showV = false;
  elec_hov = false;
  @Input('si') si = 0;
  @Input('ss') ss = 0;
  @Input('sss') sss = 0;
  @Input('sm') sm = 6;
  image1 = false;
  image2 = false;

  redd() {
		this.red = (this.elec_hov) ? '#950013' : '#f3f3f3';
	}

	ngOnInit() {
    setTimeout( () => { this.image1=true; }, 700)
    setTimeout( () => { this.image2=true; }, 1400);
	}

}
