import { Component, OnInit } from '@angular/core';
import { fade } from './app.animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.pug',
  animations: [fade],
  styleUrls: ['./app.component.styl']
})
export class AppComponent implements OnInit {
	fadeIn = false;
	title = 'portfolio';
	ngOnInit() {
		setTimeout( () => {
			this.fadeIn = true;
		}, 500);
	}
}
