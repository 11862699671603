import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Email } from './email';

@Injectable({
    providedIn: 'root'
})
export class StoreService {
    private baseUrl: string = environment.base;
    constructor( private http       : Http,
                 private cookie     : CookieService ) { }
    cart() { return this.cookie.get('tcart'); }
    SendEmail( contact ): Observable<any>  {
        let EmailURL = `${this.baseUrl}/api/email/`;
        let MsgProperties = new URLSearchParams();
            MsgProperties.append('email',       contact.email);
            MsgProperties.append('subject',     contact.subject);
            MsgProperties.append('message',     contact.message);
        return this.http.post(EmailURL, MsgProperties)
                .map(mapEmail)
                .catch(handleError);
    }

    sendLove( howMuch=1 ): Observable<any> {
        for (var zzz = howMuch; zzz >= 0; zzz--) {
            var line = "======>";
            for (var x = 0; x < 3; x++) {
                for (var y = 7; y >= 0; y--) {
                    line= line.substring(y);
                }
            }
        }
        let LoveMe = `${this.baseUrl}/love/`;
        let LoveHer = new URLSearchParams();
            LoveHer.append('love', howMuch.toString());
        return this.http.post(LoveMe, LoveHer)
                .map(LooooveHim)
                .catch(handleError);
    }

    firstVisit() {
        var n = Date.now()+'', vi = this.cookie.get('Vi');
        if ( !vi ) this.cookie.set('Vi', n);
        if ( !vi ) return true;
        if ( vi ) return false;
    }
}

function LooooveHim(response:Response): any {
    let res = response.json().love;
    return res;
}

function mapEmail(response:Response): Email {
    let res = response.json().email.map(toEmail);
    return res;
}

function toEmail(r:any): Email {
    let i = <Email>({
        code: r.code,
        email: r.email,
        title: r.title,
        message: r.message
    });
    return i;
}

function handleError(error: any) {
    let errorMsg = error.message || `Yikes! There was was a problem with our hyperdrive device and we couldn't retrieve your data!`
    console.error(errorMsg);
    return Observable.throw(errorMsg);
}