import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'hayle-coverletter',
  templateUrl: './hayle.coverletter.component.pug',
  styleUrls: ['./hayle.coverletter.component.styl']
})
export class CoverletterHayleComponent implements OnInit {

	public constructor(private titleService: Title) { }
  
	title: string;
	subtitle: string;
	tirtitle: string;
	company: string;

	ngOnInit() {
		this.title='Halye';
		this.subtitle='Human Resources';
		this.titleService.setTitle("Liam Hogan's | Hayle | Cover Letter");
		this.tirtitle='';
		this.company='Hayle'; 
	}

}
