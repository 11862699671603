import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'canadas-culture-coverletter',
  templateUrl: './canadas-culture.component.pug',
  styleUrls: ['./canadas-culture.component.styl']
})
export class CoverletterCanadasCultureComponent implements OnInit {

	public constructor(private titleService: Title) { }
  
	title: string;
	subtitle: string;
	tirtitle: string;
	company: string;

	ngOnInit() {
		this.title="Canna North";
		this.subtitle='2547 Baseline Rd.';
		this.titleService.setTitle("Liam's Canna North Cover Letter");
		this.tirtitle='';
		this.company="Canna North";
	}

}
