import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.pug',
  styleUrls: ['./phone.component.styl']
})
export class PhoneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
