import { Component, OnInit, Input } from '@angular/core';
import { twist_off, blip, fade, height_fade, show, fade_quick } from '../app.animations';
@Component({
  selector: 'home-sites',
  templateUrl: './home-sites.component.pug',
  styleUrls: ['./home-sites.component.styl'],
  animations: [twist_off, blip, fade, height_fade, show, fade_quick]
})
export class HomeSitesComponent implements OnInit {

  constructor() {this.wind_x = this.media.length; }

	wind_x: any;
	Medialength = 0;
	wind_i = 0;
	wind_shw=[];
	wind_hov=0;
	ico_shw=0;
	imgLoaded=[];
	ico_hov=[];
	_ok = 0;
	ico_i = 0;
	ico_x = 1;
	@Input('si') si = 0;
	@Input('ss') ss = 0;
	@Input('sss') sss = 0;
	@Input('sm') sm = 6;

	media = [
		{ name: "SplinterForge.io", url: "http://splinterforge.io", image: "/assets/sites/SplinterForge.png", image2:"/assets/sites/SplinterForge.png", color: "black" },
		{ name: "Dan Hogan", url: "http://danhogan.ca", image: "/assets/sites/danhogan.jpg", image2:"/assets/img/danhogan.jpg", color: "black" },
		{ name: "Tree Street", url: "https://treest.ca", image: "/assets/sites/treestreet.jpg", image2:"/assets/sites/treestreet.jpg", color: "black" },
		{ name: "Identity", url: "http://identityrpg.com/", video: "/assets/video/identity.webm", image: "/assets/sites/identity.jpg", color: "#00b5fa" },
		{ name: "Propel", url: "http://propelpm.com/", image: "/assets/sites/firstestate.png", color: "black" },
		{ name: "Shaw Centre", url: "https://www.shaw-centre.com/", image: "/assets/sites/shaw.jpg", color: "black" },
		{ name: "Bluesfest 2015", url: "http://www.ottawabluesfest.ca/", image: "/assets/sites/bluesfes.png", color: "black" },
		{ name: "Lansdowne Live", url: "https://www.tdplace.ca", image: "/assets/sites/lansdowne-live.png", color: "black" },
		{ name: "Ottawa Tourism", url: "https://ottawatourism.ca", image: "/assets/sites/ottawatourism.jpg", color: "black" },
		{ name: "Elemental", url: "http://elementaldci.com/", image: "/assets/sites/elemental.jpg", color: "black" },
		{ name: "JJ Brun", image: "/assets/sites/jjbrun.jpg", color: "black" },
		{ name: "GoldenEar", url: "https://www.goldenear.com/", image: "/assets/sites/goldenear-816x946-1.png", color: "black" },
		{ name: "Carlington Community Centre", url: "https://www.carlington.ochc.org/", image: "/assets/sites/carlington.png", color: "black" },
		{ name: "DS Plumbing", url: "https://www.outofthisworldhomeservices.com/", image: "/assets/sites/dsplumbing.png", color: "black" },
		{ name: "Brentech Signs & Designs", url: "http://brentech.ca/", image: "/assets/sites/brentechsigns.jpg", color: "black" },
		{ name: "Olympia Homes", url: "https://olympiahomes.ca/", image: "/assets/sites/olympia.jpg", color: "black" },
		{ name: "Aardvark Private Homes", image: "/assets/sites/Aardvark.jpg", color: "black" },
	];

	ngOnInit() {
		for (var i = 2; i >= 0; i--) {
			for (var a = 0; a < 1; a++) {
				this._ok++;
			}
		}
	}

}
