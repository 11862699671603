import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { push,blip,blip_slow,icon_show,twist_off,show,fade } from '../app.animations';

@Component({
  selector: 'resume',
  templateUrl: './resume.component.pug',
  animations: [push,blip,blip_slow,icon_show,twist_off,show,fade],
  styleUrls: ['./resume.component.styl']
})



export class ResumeComponent implements OnInit {
	public constructor(private titleService: Title) { }
	loaded = false;
	ngOnInit() {
		this.titleService.setTitle("Liam Hogan's Resume");
		setTimeout( () => {
			this.loaded = true;
		}, 500);
	}

}
