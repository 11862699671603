import { Component, OnInit } from '@angular/core';
import { StoreService } from '../services/store.service';
import { Email } from '../services/email';

declare var $: any;
declare var pfold: any;

@Component({
  selector: 'contact',
  templateUrl: './contact.component.pug',
  styleUrls: ['./contact.component.styl']
})
export class ContactComponent implements OnInit {

  constructor(
  	private site: StoreService
  ) { }

  sending: boolean
  email: Email;
  contactForm = new Contact();
  emailSent: Email;

  sendEmail() {
    this.sending = true;
  	this.site.SendEmail(this.contactForm).subscribe( (r) => {
      this.emailSent = r[0];
      setTimeout( () => {this.sending = false;}, 500);
 	  });
  }

  ngOnInit() {

  }
}

export class Contact {
  constructor(
    public email?: string,
    public subject?: string,
    public message?: string,
  ) {  }
}