import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { push,blip,blip_slow,icon_show,twist_off,show,fade } from '../app.animations';

@Component({
  selector: 'home-pilot',
  templateUrl: './home-pilot.component.pug',
  animations: [push,blip,blip_slow,icon_show,twist_off,show,fade],
  styleUrls: ['./home-pilot.component.styl']
})
export class HomePilotComponent implements OnInit {

  constructor() { }
  red: any;
  showV = false;
  elec_hov = false;
  @Input('si') si = 0;
  @Input('ss') ss = 0;
  @Input('sss') sss = 0;
  @Input('sm') sm = 6;
  image1 = false;
  image2 = false;
  @ViewChild("videoPlayer", { static: false }) video: ElementRef;
  play() {this.video.nativeElement.play();}
  ngOnInit() {
  }

}
